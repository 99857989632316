import React from "react";
import Layout from "../components/layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const IndexPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
  };
  return (
    <Layout>
      <section className="slider w-full mx-auto mt-8 pb-16 overflow-x-hidden">
        <Slider {...settings}>
          <div className="w-full">
            <StaticImage
              src={`../images/T90.jpg`}
              alt="Type 90/84"
              placeholder="blurred"
            />
          </div>
          <div className="w-full">
            <StaticImage
              src={`../images/T45.jpg`}
              alt="Type 45/69"
              placeholder="blurred"
            />
          </div>
        </Slider>
      </section>
      <section className="modelTypes">
        <div className="container">
          <div className="grid grid-cols-2 gap-5 md:gap-10">
            <div className="flex flex-col">
              <Link to="/type-90-84/">
                <div className="rounded-xl overflow-hidden">
                  <StaticImage
                    src={`../images/T90.jpg`}
                    alt="Type 90/84"
                    placeholder="blurred"
                    className="md:transition-all md:duration-200 md:ease-in-out md:hover:scale-105"
                    width={800}
                  />
                </div>
              </Link>
              <Link to="/type-90-84/">
                <h2 className="text-3xl md:text-4xl font-extralight mt-2">
                  <span className="text-xs md:text-sm pr-1">TYPE</span>
                  90/84
                </h2>
              </Link>
            </div>
            <div className="flex flex-col">
              <Link to="/type-45-69/">
                <div className="rounded-xl overflow-hidden">
                  <StaticImage
                    src={`../images/T45.jpg`}
                    alt="Type 45/69"
                    placeholder="blurred"
                    className="md:transition-all md:duration-200 md:ease-in-out md:hover:scale-105"
                    width={800}
                  />
                </div>
              </Link>
              <Link to="/type-45-69/">
                <h2 className="text-3xl md:text-4xl font-extralight mt-2">
                  <span className="text-xs md:text-sm pr-1">TYPE</span>
                  45/69
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => (
  <>
    <title>JEWEL Residences @ Serpong, Tangerang Selatan</title>
    <meta
      name="description"
      content="New upcoming exclusive cluster project coming to you. Strategically located at Serpong, Tangerang Selatan, Jabodetabek, Indonesia. Get your dream home now! Contact Us for more information."
    />
    <meta name="og:url" content="https://www.jewelresidences.com" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;700;800&display=swap"
      rel="stylesheet"
    ></link>
    <link
      rel="icon"
      href="https://www.jewelresidences.com/favicon.ico"
      type="image/x-icon"
    />
    <link
      rel="apple-touch-icon"
      sizes="57x57"
      href="https://www.jewelresidences.com/favicon/apple-icon-57x57.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="60x60"
      href="https://www.jewelresidences.com/favicon/apple-icon-60x60.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="72x72"
      href="https://www.jewelresidences.com/favicon/apple-icon-72x72.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="76x76"
      href="https://www.jewelresidences.com/favicon/apple-icon-76x76.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="114x114"
      href="https://www.jewelresidences.com/favicon/apple-icon-114x114.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href="https://www.jewelresidences.com/favicon/apple-icon-120x120.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="144x144"
      href="https://www.jewelresidences.com/favicon/apple-icon-144x144.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href="https://www.jewelresidences.com/favicon/apple-icon-152x152.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="https://www.jewelresidences.com/favicon/apple-icon-180x180.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href="/android-icon-192x192.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="https://www.jewelresidences.com/favicon/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href="https://www.jewelresidences.com/favicon/favicon-96x96.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="https://www.jewelresidences.com/favicon/favicon-16x16.png"
    />
    <link
      rel="manifest"
      href="https://www.jewelresidences.com/favicon/manifest.json"
    />
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta
      name="msapplication-TileImage"
      content="https://www.jewelresidences.com/favicon/ms-icon-144x144.png"
    />
    <meta name="theme-color" content="#ffffff"></meta>
    <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=GTM-K3DM7CP"
    ></script>
  </>
);
